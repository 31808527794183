export default function useCategoryList() {
  const columns = [
    { key: 'featuredImage', sortable: false },
    { key: 'title', sortable: true },
    { key: 'slug', sortable: true },
    { key: 'description', sortable: true },
    { key: 'models', label: 'models', sortable: false },
    { key: 'Action', sortable: false },
    // { key: 'stock', sortable: true },
  ];
  const modelColumns = [
    
    { key: 'featuredImage', sortable: false },
    { key: 'title', sortable: true },
    { key: 'slug', sortable: true },
    { key: 'Action', sortable: false },

    // { key: 'stock', sortable: true },
  ];

  return {
    columns,
    modelColumns
  };
}
